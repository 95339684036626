import React, { useEffect, useRef, useState } from 'react'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AppBarComponent, ContainerComponent } from '~/components'

import {
  FixedEvidencesComponent,
  HeaderPlanning,
  StepAccordion,
  VariableEvidencesComponent,
} from '~/containers/private/Fund1/Planning'
import { OrientationItem } from '~/containers/private/Fund1/Planning/StepAccordion/StepAccordion.interfaces'

import { useLessonPlanningPageContext } from './context'
import * as S from './styles'

export const LessonPlanningView = () => {
  const { fixedEvidences, guidance, steps, txTitleLesson, evidencesByActivityOnStepTwo } =
    useLessonPlanningPageContext()

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const variablesRefContainer = useRef<HTMLDivElement>(null)
  const overallStepRef = useRef<HTMLDivElement>(null)
  const firstStepRef = useRef<HTMLDivElement>(null)
  const secondStepRef = useRef<HTMLDivElement>(null)
  const thirdStepRef = useRef<HTMLDivElement>(null)
  const [expadedGeneralOrientation, setExpadedGeneralOrientation] = useState(true)

  const [activedTab, setActivedTab] = useState(0)

  const scrollToNextStep = (ref: React.RefObject<HTMLDivElement>) => {
    const headerHeight = document.getElementById('header')?.clientHeight ?? 0
    const position = isMobile ? headerHeight - 20 : headerHeight + 50
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - position,
      })
    }
  }

  const handleSetActiveTab = (newActivedTab: number) => {
    switch (newActivedTab) {
      case 0:
        scrollToNextStep(variablesRefContainer)
        break
      case 1:
        scrollToNextStep(firstStepRef)
        break
      case 2:
        scrollToNextStep(secondStepRef)
        break
      case 3:
        scrollToNextStep(thirdStepRef)
        break
      default:
        break
    }
    setActivedTab(newActivedTab)
  }

  const handleScroll = () => {
    const overallStepOffset = overallStepRef.current?.offsetTop ?? 0
    const firstStepOffset = firstStepRef.current?.offsetTop ?? 0
    const secondStepOffset = secondStepRef.current?.offsetTop ?? 0
    const thirdStepOffset = thirdStepRef.current?.offsetTop ?? 0

    const scrollPosition = window.scrollY + 280

    if (scrollPosition >= overallStepOffset && scrollPosition < firstStepOffset) {
      setActivedTab(0)
    } else if (scrollPosition >= firstStepOffset && scrollPosition < secondStepOffset) {
      setActivedTab(1)
    } else if (scrollPosition >= secondStepOffset && scrollPosition < thirdStepOffset) {
      setActivedTab(2)
    } else if (scrollPosition >= thirdStepOffset) {
      setActivedTab(3)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <>
          <HeaderPlanning
            txNameLesson={txTitleLesson}
            handleSetActiveTab={handleSetActiveTab}
            activedTab={activedTab}
            isOffline
          />
          <S.Container>
            {fixedEvidences && fixedEvidences.length > 0 && (
              <S.FixedEvidencesContainer>
                <FixedEvidencesComponent steps={fixedEvidences} />
              </S.FixedEvidencesContainer>
            )}
            {evidencesByActivityOnStepTwo && evidencesByActivityOnStepTwo.length > 0 && (
              <S.VariableEvidencesContainer ref={variablesRefContainer}>
                <VariableEvidencesComponent steps={evidencesByActivityOnStepTwo} />
              </S.VariableEvidencesContainer>
            )}
            {guidance.length > 0 && (
              <StepAccordion
                key={'default'}
                title="Orientações Gerais"
                expaded={expadedGeneralOrientation}
                onToggle={() => setExpadedGeneralOrientation(!expadedGeneralOrientation)}
                orientationItems={[
                  {
                    txOrientationCode: guidance,
                    lessonActivitySupportReference: [],
                  },
                ]}
                supportReferences={[]}
                accordionRef={overallStepRef}
                type="default"
              />
            )}
            {steps?.[0]?.lessonActivity?.[0]?.lessonActivityOrientation?.map(
              (orientation: OrientationItem, index: number) => (
                <StepAccordion
                  key={`orientation-${index}`}
                  title={orientation?.txTitle || `Orientação ${index + 1}`}
                  expaded={false}
                  orientationItems={orientation ? [orientation] : []}
                  txGuidance={orientation?.txOrientationCode}
                  supportReferences={orientation?.lessonActivitySupportReference || []}
                  type={`firstStep`}
                  nonCollapsible={true}
                  accordionRef={firstStepRef}
                />
              ),
            )}
          </S.Container>
        </>
      </ContainerComponent>
    </>
  )
}
